@import url(https://use.typekit.net/ejy7pnr.css);
@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.separator[data-v-7afecb56] {
  margin-top: 40px;
}
.head[data-v-7afecb56] {
  text-align: center;
  margin: 40px 20%;
}
.head p[data-v-7afecb56] {
    margin-top: 19px;
    font-weight: 700;
    font-size: 19px;
    color: #aca79e;
}
.intro[data-v-7afecb56] {
  color: #3b3d42;
  padding-bottom: 40px;
}


/*# sourceMappingURL=login.b9cc0831.css.map*/
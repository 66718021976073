
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        




















































.separator {
  margin-top: 40px;
}

.head {
  text-align: center;
  margin: 40px 20%;
  p {
    margin-top: 19px;
    font-weight: 700;
    font-size: 19px;
    color: $gray-500;
  }
}
.intro {
  color: $gray-800;
  padding-bottom: 40px;
}
